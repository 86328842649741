import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import JasonImg from '../images/jason-lipps-coastal-advisory-group.png';
import WCImg from '../images/wc-stewart-coastal-advisory-group.png';
import Chrisimg from '../images/chris-alverson-coastal-advisory-group.png'
import JayImg from '../images/jay-hasselbeck-coastal-advisory-group.png';

import Layout from "../components/layout"
import SEO from "../components/seo"

const CrewPage = (props) => (
    <Layout location={props.location}>
      <SEO title="Meet the Crew" description="W.C Stewart, Chris Alverson, Jason Lipps, Jay Hasslebeck. Award-winning team dedicated to helping you achieve your financial goals. See how..."/>
      <h1 style={styles.header}>Our Crew</h1>
      <div style={styles.body}>
      <h5 style={styles.italic}>Our Crew Keeps You on Course.</h5>
      <p>
        At the Coastal Advisory Group our award-winning team is dedicated to helping you achieve your financial goals by 
        providing you the full-scope of financial planning services. See how our years of experience and specialized 
        knowledge can make a difference!
      </p>
      <Container fluid={true}>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={WCImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>W.C. Stewart</span> <br/>
              Investment Advisor Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 5
            </p>
          </Col>
          <Col>
            W.C. has over 30 years of experience in the insurance and finance industry. He is a graduate of Lipscomb University in Nashville, Tennessee and earned a B.S. degree in Business Management. He helped develop a unique market in the school systems offering employee benefits along with retirement plans. In 1994, he received his securities license and continued his distinguished career helping families with their retirement planning. W.C. offers one on one consulting in his office in Covington, Kentucky and helps each individual as they develop a plan together helping ensure they enjoy their retirement years. W.C. believes that proper and strategic planning, can help clients manage, grow and protect the family’s wealth.<br/><br/>
            W.C. and his wife, Beth, enjoy volunteering in the community and are the proud parents of two sons. He enjoys attending and watching U.K. basketball games, traveling, taking an occasional cruise and spending time with his family and friends.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={Chrisimg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Chris Alverson</span> <br/>
              Investment Advisor Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 8
            </p>
          </Col>
          <Col>
          Chris Alverson is a Financial Professional located in Cincinnati, OH. Chris came to the investment industry after experiencing success in both the music and education fields.
          <br/><br/>
          Chris' interest in economics goes back to his Childhood. At the age of twelve Chris' coin collection presented a very interesting question to his young mind: How could a U.S. silver dollar cost $7? This simple question sparked an interest in economics and the nature of inflation.
          <br/><br/>
          After completing graduate studies at UC’s College-Conservatory of Music Chris taught undergraduate students as Earlham College’s Jazz Director. Chris has had the privilege of performing with artists such as Arturo Sandoval, Don Braden, Gordon Brisker and H-Bomb Ferguson. Additionally, Chris has performed with the Kentucky Symphony, Earlham Orchestra and CCM’s Philharmonia.
          <br/><br/>
          The recession in 2008 renewed Chris' passion for economics and markets. This curiosity led him to Austrian Economics and the work of Ludwig Von Mises. Chris' research led him down another road resulting in a career change. Chris took a position teaching at the high school level and spent the following ten years as a Title I Math Coach and Consumer Mathematics Teacher exposing high schoolers to the practical applications of economics in their lives.
          <br/><br/>
          Chris is pleased to apply his knowledge of economics and markets in service of his clients.
          <br/><br/>
          He and his wife Lee are the proud parents of both their daughter and son. He enjoys reading, biking and making music in his spare time. Chris currently plays bass guitar in his church’s worship band.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={JasonImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Jason R. Lipps</span> <br/>
              Investment Advisor Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 7
            </p>
          </Col>
          <Col>
            For over 10 years, Jason Lipps has been driven by his passion to help his clients learn sound investment 
            principles so they can make smarter decisions and enjoy greater freedom in their total financial lives. He 
            offers his clients one-on-one meetings outside of the office to ensure their convenience and comfortable 
            discussion.<br/>
            After graduating from Miami University with a Bachelor of Science in Finance, he started his own business as 
            an Investment Advisor Representative and focused on Comprehensive Financial Planning with Brecek & Young 
            Advisors. In 2006, he moved his practice to Pension Corporation of America, and in 2010 he became a Wealth 
            Manager with the Coastal Advisory Group, winning the 2011 and 2012 FIVE STAR Wealth Manager Awards.<br/><br/>
            He and his wife, Kerri, are the proud parents of their first daughter. He also enjoys golfing, reading and 
            taking his dog to the park. He holds an Ohio Life, Health, and Disability Insurance license.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={JayImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Jay Hasselbeck</span> <br/>
              Database Manager<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 2
            </p>
          </Col>
          <Col>
            As Database Manager, Jay Hasselbeck helps keep private wealth management private by maintaining our 
            databases, reviewing accounting transactions and assisting in communications and investor relations. 
            Jay’s diligence and integrity are unparalleled when it comes to serving our select client family. He 
            is the go-to guy for technology issues and oversees the audio-visual installations for seminars and 
            conferences. Jay received his Associate Degree in Accounting from Northern Kentucky Technical College.
            <br/><br/>
            When not in the office, he is an avid player of Fantasy Football with an affinity for movie-going and 
            playing recreational basketball and golf.
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    color: '#8d1b38',
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  },
  bioImg: {
    width: '100%',
    maxWidth: '166px'
  }
}

export default CrewPage
